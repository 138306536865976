import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import * as style from '../assets/style/pages/about-page.module.scss';

const AboutPage = ({ location, data }) => {
  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'O firmie', url: '/o-firmie' }
  ];

  const aboutPage = () => {
    const pageData = data.aboutPage;
    return {
      title: pageData.title.value,
      text: pageData.text.value,
      photo: pageData.photo.value
    };
  };

  return (
    <Layout>
      <Seo title="O firmie">
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(({ title, url }, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.about}>
        <div className="container">
          <GatsbyImage image={getImage(aboutPage().photo)} alt="Widok firmy z lotu ptaka" />
          <div className={`${style.about__content} col-12 col-lg-10`}>
            <h1>{aboutPage().title}</h1>
            <div dangerouslySetInnerHTML={{ __html: aboutPage().text }}></div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    aboutPage: cockpitAboutPage {
      title {
        value
      }
      text {
        value
      }
      photo {
        value {
          childImageSharp {
            gatsbyImageData(
              width: 1110,
              height: 500,
              quality: 100,
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;

export default AboutPage;
